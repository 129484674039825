<!--
 * @Author: js
 * @Date: 2022-04-01 17:27:44
 * @LastEditors: js
 * @LastEditTime: 2022-07-27 16:41:13
 * @Description: desc
 * @FilePath: /zktree_official_website/src/views/News.vue
-->

<template>
  <div class="news">
    <BannerPrivinceInfo></BannerPrivinceInfo>
    <div class="news-con" v-if="$route.query.city === 'chongqin'">
      <p style="visibility: visible;">
        重庆市2022年4月高等教育自学考试将于2022年4月16日至17日举行。为保障广大考生和考试工作人员的生命安全和身体健康，我们特别提醒广大考生：
      </p>
      <p style="visibility: visible;"><br style="visibility: visible;" /></p>
      <p style="visibility: visible;">一、考前准备</p>
      <p style="visibility: visible;">
        1.
        考生应按照当地卫生健康防疫部门的要求，接种新冠病毒疫苗。考生务必注意科学防疫，注重个人卫生，减少外出；外出佩戴口罩，保持适当社交距离；勤洗手，常通风，不扎堆，不聚会，合理饮食。避免接触有市外旅行居住史、境外人员接触史的人员。
      </p>
      <p style="visibility: visible;">
        <span style="color: rgb(217, 33, 66); visibility: visible;">
          2．自2022年4月2日至4月15日，考生须访问重庆市高等教育自学考试信息管理系统(http://zk.cqksy.cn)，进入“重庆市2022年4月高等教育自学考试健康防疫信息上报系统”，完成每日健康状况登记。考生进入该系统进行微信绑定后，可通过“重庆招考”微信公众号“健康上报”菜单进行登记。
        </span>
      </p>
      <p style="visibility: visible;">
        3．考生应尽可能保持考前在渝学习生活14
        天以上且自觉做好自我健康监测，并通过微信或支付宝小程序等实名申领健康码(以下简称“渝康码”)和通信大数据行程卡。考前14
        天起，考生应每日自行测量体温和监测健康状况，持续关注本人“渝康码”和大数据行程卡状态。渝康码或大数据行程卡状态异常的考生，应按照重庆市疫情防控管理规定的转码条件按时按要求完成核酸检测、及时进行转码申报，“渝康码”和大数据行程卡均无异常方可参加考试。
      </p>
      <p style="visibility: visible;">
        4．外地返渝考生请使用手机关注“重庆疾控”微信公众号，通过“疫情防控”栏查看重庆市疫情防控政策，根据本人出发地风险等级的不同要求，严格遵守健康监测、核酸检测、居家隔离等相应疫情防控规定。建议在返渝前刷新了解或拨打电话咨询。
      </p>
      <p style="visibility: visible;">
        5．考前14天有市外旅居史或者有中高风险地区旅居史的考生，进入考点时须提供
        <span
          style="
            text-decoration: underline;
            color: rgb(217, 33, 66);
            visibility: visible;
          "
        >
          重庆市本地核酸检测机构出具的本人考试前48
          小时内（2022年4月14日09：00以后）核酸检测阴性（电子或纸质）证明。
        </span>
      </p>
      <p style="visibility: visible;"><br style="visibility: visible;" /></p>
      <p style="visibility: visible;">二、入场检查</p>
      <p style="visibility: visible;">
        1．建议考生按规定入场时间提前30分钟以上到达考点，预留充足的进场时间以免误考。自行准备并佩戴一次性医用口罩或医用外科口罩，未戴口罩的考生不得进入考点，准备好“渝康码”、“通信大数据行程卡”以备查验。考前14天有市外旅居史的考生，还需提交核酸检测阴性（电子或纸质）证明等。考生通过检测通道时，应保持适当间隔，有序接受体温测量及入场安检，主动出示本人“渝康码”、通信大数据行程卡以及核酸检测阴性（电子或纸质）证明。考生进入考点、考场时不得因为佩戴口罩而影响身份识别。
      </p>
      <p>
        2.
        对核酸检测报告异常，“渝康码”或通信大数据行程卡异常，或入场时发现体温异常，或在考前、考试过程中有发热、干咳、乏力、鼻塞、流涕、咽痛、腹泻等症状的考生，考点防疫专业人员将进行专业研判，考生须配合并服从考点的相应安排。
      </p>
      <p><br /></p>
      <p>三、应试要求</p>
      <p>1．除考生和考试工作人员外，其余无关人员不得进入考点或考试区域。</p>
      <p>
        2．考生在进入考场前要佩戴口罩，进入考场就座后，可以自主决定是否继续佩戴；隔离考场的考生要全程佩戴口罩。
      </p>
      <p>
        3．考生如在考试期间出现发热、干咳、乏力、鼻塞、流涕、咽痛、嗅觉味觉减退、结膜炎、肌痛和腹泻等不适症状，应立即向监考员报告。
      </p>
      <p>
        4．考试结束时，应按考试工作人员的指令，有序错峰离场，保持人员间距，不扎堆、不驻留，不得在考点内滞留。
      </p>
      <p><br /></p>
      <p>四、其他要求</p>
      <p>
        1．考生应主动配合考点工作人员做好身体健康检测，凡隐瞒或谎报旅居史、接触史、健康状况等疫情防控重点信息，或拒不配合工作人员进行防疫检测、询问、排查的，将取消考试资格。如有违法情况，将依法追究法律责任。
      </p>
      <p>
        2．参考要求将根据疫情防控形势、防控政策、入校规定等适时调整，请考生密切关注相关动态。
      </p>
    </div>
    <div class="news-con" v-else-if="$route.query.city === 'quanzhou'">
      <h2 style="font-weight: bold; font-size: 1.2em; text-align: center;">
        关于延期举行泉州市2022年4月自学考试的通告
      </h2>
      <br />
      <p style="line-height: 1.5em; text-indent: 2em;">
        鉴于目前我市新冠肺炎疫情防控形势，为切实保障广大考生和考试工作人员生命安全和身体健康，经福建省教育厅批准，
        <span style="color: red;">
          我市原定于4月16-17日举行的全国高等教育自学考试延期到10月份举行。
        </span>
        考生已缴纳的考试报名费将由福建省教育考试院
        <span style="color: red;">按原缴费渠道全额退回，</span>
        考生无需提出退费申请，
        <span style="color: red;">退费处理预计于6月底完成。</span>
        <br />
        因考试延期给广大考生带来的不便，敬请谅解！
        <br />
        特此通告。
      </p>
      <br />
      <p style="text-align: right; line-height: 1.5em;">
        泉州市教育招生考试院
        <br />
        2022年4月9日
      </p>
    </div>
    <div class="news-con" v-else-if="$route.query.city === 'ningde'">
      <h2 style="font-weight: bold; font-size: 1.2em; text-align: center;">
        关千取消宁德市2022年上半年高等教育自学考试的通告
      </h2>
      <br />
      <p style="line-height: 1.5em; text-indent: 2em;">
        根据省、市疫情防控部署要求，鉴于目前我
        市新冠肺炎疫情防控形势，为切实保障广大
        考生和考试工作人员生命安全和身体健康，
        经福建省教育厅批准，取消我市原定于4月
        16-17日举行的2022年上半年全国高等教育
        自学考试。考生已缴纳的考试报名费将由福
        建省教育考试院按原缴费渠道全额退回，考
        生无需提出退费申请．退费处理预计于6月 底完成。
        <br />
        因疫情原因取消考试给广大考生带来的 不便，敬请谅解！
        <br />
        特此通告
        <br />
        联系电话：0593-2915691
      </p>
      <br />
      <p style="text-align: right; line-height: 1.5em;">
        宁德市教育局
        <br />
        2022年4月11日
      </p>
    </div>
    <div class="news-con" v-else-if="$route.query.type === 'news'">
      <div class="news-title" v-html="$store.state.news.title"></div>
      <div v-html="$store.state.news.news"></div>
    </div>
    <div class="news-con" v-else>
      <div v-html="$store.state.htmlCon"></div>
    </div>
  </div>
</template>
<script>
import BannerPrivinceInfo from '@/components/banner/BannerPrivinceInfo.vue'
export default {
  components: {
    BannerPrivinceInfo,
    // PrivinceAll,
    // EpidemicInfo,
  },
}
</script>
<style lang="scss">
.news {
  &-con {
    padding: 15px 20px;
    padding-bottom: 50px;
    font-size: 16px;
    max-width: 1140px;
    margin: 0 auto;
  }
  .news-title {
    text-align: center;
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
  }
}
</style>
